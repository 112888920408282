import * as React from "react";

// Components
import Layout from "../../components/Layouts/Layouts";
import Seo from "../../components/Layouts/Seo";
import Banner from "../../components/Content/Banner/Index";
import LeadIn from "../../components/Content/LeadIn/Index";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Training"
        description="Conex Bänninger offers product training and can assist with technical advice and expertise. We also provide certification for large projects where this is a requirement. "
      />
      <Banner
        type="landing"
        title="Training"
        summary="The support you need to install with confidence."
        featureMediaUrl="/banner/landing-training.jpg"
        featureMediaTitle="Installing using a conex tool"
        removePadding={true}
      />
      <LeadIn
        brandMediaTitle="Conex Bänninger Logo"
        brandMediaWidth="306px"
        summary="When it comes to using a press system, you want peace of mind that things will run smoothly."
        content="Conex Bänninger offers product training and can assist with technical advice and expertise. We also provide certification for large projects where this is a requirement. To request training, contact us and we’ll be in touch within 48 hours."
        buttonValue="Contact"
        buttonUrl="/contact"
      />
      {/* <LeadIn
        section="primary"
        brandMediaUrl="/icons/icon-download.svg"
        brandMediaTitle="Download"
        brandMediaWidth="53px"
        summary="Looking for something specific? Visit our Downloads section"
        buttonValue="Downloads"
        buttonUrl="/resources/downloads"
        paddingSize="small"
      /> */}
    </Layout>
  );
};

export default IndexPage;
